<!-- Alcocell Landing Page -->
<template>
  <div class="container">

    <header class="header">
      <div class="header-content">
        <img src="@/assets/logo-1.svg" alt="Logo" class="header-logo">
        <div class="contact-and-demo">
          <div class="contact-text" @click="showPopup = true">Contact us</div>
          <button class="download-our-brochure-button" @click="openDownloadBrochure">
            <span>Download Our Brochure</span>
          </button>
        </div>
      </div>
    </header>
    <!-- Rest of your content goes here -->

    <!-- Frame 1: Hero Section -->
    <section class="hero-section">
      <!-- Frame 2: Container for text and button -->
      <div class="frame2-container">
        <!-- Frame 3: Title and Subtitle -->
        <h1 class="frame3-title">Digital Twin And AI <span class="non-bold">For Bioprocesses</span></h1>
        <p class="frame3-subtitle">Enabling data-driven decisions for the cell-based industry</p>
        <button class="book-a-demo-button" @click="openDemoBooking">
          <img src="@/assets/arrow-down-right.svg" alt="Arrow" class="arrow-icon" />
          <span>Book a Demo</span>
        </button>
      </div>
    </section>

    <div class="embedded-iframes" v-if="embedVisible">
      <iframe v-if="currentEmbed === 'google'" :src="googleCalendarLink" frameborder="0"
      style="width: 80%; height: 100vh; margin: auto; display: block;"></iframe>
      <!-- <iframe v-if="currentEmbed === 'brochure'" :src="brochureLink" frameborder="0"
        style="width: 80%; height: 100vh; margin: auto; display: block;"></iframe> -->
    </div>

    <div class="vector-container">
      <div class="vector vector-1"></div>
      <div class="vector vector-2"></div>
      <div class="vector vector-3"></div>
    </div>


    <!-- Section with stacked images -->
    <section class="stacked-images">
      <div class="image-container">
        <!-- Adjust the image paths and z-index as needed -->
        <img src="@/assets/bioreactor3.svg" alt="Image 1" class="image image-1">
        <img src="@/assets/bioreactor-dt.png" alt="Image 2" class="image image-2">
        <img src="@/assets/DTAI.svg" alt="Image 3" class="image image-3">
        <!-- <img src="@/assets/Layer_1.png" alt="Background Image 1" class="background-image background-image-1">
        <img src="@/assets/Layer_1.png" alt="Background Image 2" class="background-image background-image-2"> -->
        <img src="@/assets/desktop01012.svg" style="width: 100%;" class="background-image background-image-0101">
        <img src="@/assets/mobile0101.svg" class="background-image background-image-0101-mobile">
      </div>
    </section>

    <!-- New frame with four sections -->
    <section class="icon-frame">
      <div class="icon-section">
        <img src="@/assets/expense.svg" alt="Expense Icon" class="icon-image" />
        <div class="icon-text background-color">
          <p>Higher Yield</p>
        </div>
      </div>
      <div class="icon-section">
        <img src="@/assets/coin-up-arrow.svg" alt="Coin Up Arrow Icon" class="icon-image" />
        <div class="icon-text background-color">
          <p>Lower Cost</p>
        </div>
      </div>
      <div class="icon-section">
        <img src="@/assets/process.svg" alt="Process Icon" class="icon-image" />
        <div class="icon-text background-color">
          <p>Stable Bioprocess</p>
        </div>
      </div>
      <div class="icon-section">
        <img src="@/assets/cart-shopping-fast.svg" alt="Shopping Cart Fast Icon" class="icon-image" />
        <div class="icon-text background-color">
          <p>Accelerate Time To Market</p>
        </div>
      </div>
    </section>

    <section class="image-section">
      <div class="desktop-image">
        <div class="centered-text">
          <span class="thin-text">Combining the traditional method of bioprocess <br> optimization with a </span>
          <strong>model-based AI solution</strong>
        </div>
      </div>
      <div class="mobile-image" style="display: none;"> <!-- Initially hidden -->
        <div class="centered-text">
          <span class="thin-text">Combining the traditional method of bioprocess <br> optimization with a </span> <br>
          <strong>model-based AI solution</strong>
        </div>
      </div>
    </section>

    <div class="footer">
      <!-- Main Frame -->
      <div class="footer-main-frame">
        <!-- Frame 1 -->
        <div class="footer-frame-1">
          <img src="@/assets/footer-icon.svg" alt="Footer Icon" class="footer-icon">
          <hr class="horizontal-line-mobile">

          <div class="nav-list">
            <div class="nav-item">
              <!-- <img src="@/assets/linkedin1.svg" alt="LinkedIn Icon" class="linkedin-icon"> -->
              <!-- <span>Follow us</span> -->
            </div>
            <!-- <div class="separator-line"></div> -->
            <div class="nav-item get-in-touch" @click="showPopup = true">
              <img src="@/assets/at1.svg" alt="At1 Icon" class="at1-icon">
              <span>Get in touch</span>
            </div>
          </div>
        </div>
        <!-- Horizontal Line -->
        <hr class="horizontal-line">
        <!-- Frame 2 -->
        <div class="footer-frame-2">
          <div class="copyright-text">© 2024 Algocell. All rights reserved.</div>
          <!-- <div class="design-text">
            <span>Designed with</span>
            <img src="@/assets/heart1.svg" alt="Heart Icon" class="heart-icon">
            <span>by Whitespace</span>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Pop-up window -->
    <div class="popup-overlay" v-if="showPopup" @click.self="closePopup">
      <div class="popup-content" @click.stop>
        <h2 class="popup-header">Contact Us</h2>
        <!-- Existing Email Input -->
        <input type="email" placeholder="Enter your email" v-model="email" style="max-width: 90%;">
        <!-- New Full Name Input -->
        <input type="text" placeholder="Full Name" v-model="fullName" style="max-width: 90%;">
        <!-- New Company Name Input -->
        <input type="text" placeholder="Company Name" v-model="companyName" style="max-width: 90%;">
        <!-- Existing Comments Textarea -->
        <textarea placeholder="What's on your mind?" v-model="comments" maxlength="400"
          style="max-width: 90%; min-width: 90%; max-height: 30vh; min-height: 5vh; height: 10vh;"></textarea>
        <button class="submit" @click="handleSubmit">Submit</button>
        <button class="close" @click="closePopup">Close</button>
      </div>
    </div>

  </div>
</template>


<script setup>
import { ref } from 'vue';
import axios from 'axios';

const embedVisible = ref(false);
const currentEmbed = ref('');  // This will store 'google' or 'brochure'
const googleCalendarLink = 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ0NuGPdNIAezD1dOt3vdxCKsJHr_bAv-XQoJsKbmGkPytcOcQf59PA-I3q2umdFShFRALL8T4mp';
// const brochureLink = 'https://docsend.com/v/9qxdx/algocellbrochure2';

const showPopup = ref(false);

const email = ref('');
const comments = ref('');
const fullName = ref('');
const companyName = ref('');

// const openDemoBooking = () => {
//   const url = 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ0NuGPdNIAezD1dOt3vdxCKsJHr_bAv-XQoJsKbmGkPytcOcQf59PA-I3q2umdFShFRALL8T4mp';
//   window.open(url, '_blank');
// };
const openDownloadBrochure = () => {
  const url = 'https://docsend.com/v/9qxdx/algocellbrochure4';
  window.open(url, '_blank');
};

const toggleEmbed = (embedType) => {
  if (currentEmbed.value === embedType && embedVisible.value) {
    embedVisible.value = false;  // If the same button is clicked and the embed is visible, hide it
  } else {
    currentEmbed.value = embedType;
    embedVisible.value = true;   // Show the embed for the selected type
  }
};

const openDemoBooking = () => {
  toggleEmbed('google');
};

// const openDownloadBrochure = () => {
//   toggleEmbed('brochure');
// };


const closePopup = () => {
  showPopup.value = false;
};

// const apiEndPoint = 'https://fpi63crq3h.execute-api.eu-central-1.amazonaws.com/LandingPageAPI/auto-email';
const apiEndPoint = 'https://fb2vdaodw8.execute-api.eu-central-1.amazonaws.com/AlgoCellLandingPageAPI/book-a-demo';
const handleSubmit = async () => {
  try {
    const response = await axios.post(apiEndPoint, {
      email: email.value,
      comments: comments.value,
      fullName: fullName.value,
      companyName: companyName.value,
    });
    console.log(response);
    email.value = '';
    comments.value = '';
    fullName.value = '';
    companyName.value = '';
    alert('Email sent successfully!');
  } catch (error) {
    console.error('Error sending email:', error);
    alert('Failed to send email.');
  }
  closePopup();

};
</script>
<style scoped>
/* ####################################################################################Adjustments for smaller screens############################################################## */
@media screen and (max-width: 768px) {

  /* @media screen and (max-width: 600px) { */
  .header {
    width: 100% !important;
    height: 70px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F6F6FB;
    /* margin-left: -8px;
    margin-top: -8px;
    margin-right: -16px!important; */

  }

  .header-content {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .header-logo {
    width: 104.9px !important;
    height: 26.22px !important;
    margin-top: 5px !important;
  }

  .arrow-icon {
    width: 18.89px !important;
    height: 18.86px !important;
    padding: 3.87px !important;
  }

  .book-a-demo-button {
    width: 136.07px !important;
    height: 33px !important;
    padding: 2.86px 0 !important;
    align-items: center !important;
    border-radius: 785.65px 0 0 0 !important;
    background: #0B0AED !important;

    display: inline-flex !important;
    justify-content: center !important;
    border-radius: 999px !important;
    cursor: pointer !important;
  }

  .book-a-demo-button img {
    width: 18.89px;
    height: 18.86px;
    margin-right: 2.29px;
  }

  .book-a-demo-button span {
    font-family: Figtree;
    font-size: 13px;
    font-weight: 700;
    line-height: 18.87px;
    text-align: center;
  }

  .download-our-brochure-button {
    width: 136.07px !important;
    height: 33px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    align-items: center !important;
    border-radius: 785.65px 0 0 0 !important;
    background: #0a61ed !important;

    display: inline-flex !important;
    justify-content: center !important;
    border-radius: 999px !important;
    cursor: pointer !important;
  }

  /* .download-our-brochure-button img {
    width: 18.89px;
    height: 18.86px;
    margin-right: 2.29px;
  } */

  .download-our-brochure-button span {
    font-family: Figtree !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18.87px !important;
    text-align: center !important;
  }



  .vector {

    display: none;
  }

  .vector-1 {
    display: none;

  }

  .vector-2 {
    left: 280px !important;
    top: 40px !important;
  }


  .vector-3 {
    display: none;

  }

  .contact-text {
    display: none;
  }

  .hero-section {
    gap: 24px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }


  .frame2-container {
    width: 335px !important;
    height: 201px !important;
  }

  .frame3-title {
    width: 335px !important;
    height: 76px !important;
    font-family: Figtree;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 38.4px !important;
    text-align: center !important;
    font-weight: 600 !important;
    white-space: normal;
  }


  .frame3-title span {
    display: block;
  }

  .frame3-subtitle {
    width: 335px !important;
    height: 52px !important;

    font-family: Figtree;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    text-align: center !important;

  }

  /* .book-a-demo-button2 {
    width: 155.07px !important;
    height: 33px !important;
    padding: 2.86px 0 !important;
    align-items: center !important;
    border-radius: 785.65px 0 0 0 !important;
    background: #0B0AED !important;
    display: inline-flex !important;
    justify-content: center !important;
    border-radius: 999px !important;
    cursor: pointer !important;
    color: white;
  } */


  .stacked-images {
    width: 100% !important;
    /* width: 243px !important; */
    height: 556px !important;
    margin-top: 55px !important;


  }

  .image-container {
    position: relative !important;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    height: 100% !important;
  }

  .image {
    position: absolute;
  }


  .image-1 {
    width: 179.85px !important;
    height: 242.16px !important;
    top: 50px !important;
    margin-left: -200px !important;
    /* left: 25px !important; */


  }

  .image-2 {
    width: 200.12px !important;
    height: 335px !important;
    margin-left: 90px !important;
    /* left: 125px; */


  }

  .image-3 {
    width: 86.6px !important;
    height: 86.6px !important;
    margin-left: 280px !important;
    /* left: 270px; */

    border-radius: 628.9px !important;
  }

  .background-image-0101-mobile {
    display: flex !important;
    width: 100% !important;
    height: 410px;
    z-index: -1;
  }

  .background-image-0101 {
    display: none;
  }

  /* .background-image-0101{
    display: none;
  } */
  /* .hero-section * {
    display: none;
  } */

  /* .image-container * {
    display: none;
  }  */

  .icon-frame {
    flex-direction: column;
    /* Change flex-direction to column to stack items vertically */
    margin-top: -200px;
  }

  .icon-section {
    flex-direction: row !important;
    display: flex !important;
    align-items: center !important;
    width: 80% !important;
    /* Adjust the width to occupy full width */
    border-right: none !important;
    /* Remove right border */
    padding: 20px 0;
    /* Adjust padding for spacing */
    text-align: center;
    /* Center align text */
    border-bottom: 1px solid #C8C8D8
  }

  .icon-image {
    margin-right: 25px;
    /* Adds some space between the icon and the text */
  }

  /* .icon-text {
    text-align: left!important;
  } */

  .desktop-image {
    display: none !important;
    /* Hide desktop image on mobile */
  }

  .mobile-image {
    display: block !important;
    background-image: url("@/assets/mobileScrollingVector.png") !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    overflow: hidden !important;
    background-size: 120% !important;
    background-position: center !important;
    /* Add this line */

    position: relative;
    background-color: #0B0AED;
    border-radius: 12px;
    width: 90%;
    /* Adjust as needed */
    height: 300px;
    /* Adjust as needed */
    margin: 50px auto 0;
    /* 50px top margin, auto left and right margins, 0 bottom margin */
  }


  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    /* Adjust color as needed */
    font-family: Figtree;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 47.36px;
    text-align: center;
    width: 80%;
    /* Adjust as needed */
  }

  .thin-text {
    font-weight: 300;
    /* Adjust as needed */
  }

  .footer-main-frame {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
  }

  /* Logo and horizontal line container */
  .footer-frame-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }



  .nav-list {
    flex-direction: row;
    justify-content: center;
    gap: 0;
    /* Since they will be separated by a line, not a gap */
  }

  .nav-item {
    justify-content: center;

  }


  .horizontal-line {
    display: none;
  }

  .horizontal-line-mobile {
    display: flex !important;
    width: 100%;
    margin: 20px 0;
    border: 1px solid #374151
  }


  /* The separator now separates "Follow us" and "Get in Touch" */
  .separator-line {
    width: 1px;
    /* For a vertical line */
    height: auto;
    /* Adjust according to the design */
    border-left: 1px solid #374151;
    margin: 0 20px;
    /* Space around the separator */
  }

  /* Frame 2 for copyright and design credit */
  .footer-frame-2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    /* Spacing between copyright and design credit */
    margin-top: 20px;
    /* Space after contacts */
  }

  .nav-item span,
  .copyright-text,
  .design-text {
    white-space: nowrap;
  }

  /* Styling for the pop-up overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    z-index: 999;
    /* Ensure it's on top of other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Styling for the pop-up content */
  .popup-content {
    background-color: #fff;
    /* White background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Shadow effect */
    max-width: 400px;
    /* Set max width for the pop-up */
  }

  /* Style for the input fields */
  .popup-content input,
  .popup-content textarea {
    width: 100%;
    margin-bottom: 1px;
    /* Add spacing between input fields */
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 1px;
  }

  /* Style for the submit and close buttons */
  .popup-content button {
    padding: 1px 2px;
    margin-top: 1px;
    /* Add spacing between buttons and inputs */
    border: none;
    border-radius: 1px;
    cursor: pointer;
  }

  /* Style for the close button */
  .popup-content button.close {
    background-color: #ccc;
    color: #333;
    margin-right: 1px;
    margin-left: 1px;
    /* Add spacing between buttons */
  }

  /* Style for the submit button */
  .popup-content button.submit {
    background-color: #007bff;
    color: #fff;
    margin: 1px;
  }



  /* Additionally, hide children elements within the icon frame section */
  /* .icon-frame * {
    display: none;
  } */


}




/* ########################## HEADER ######################################## */
.header {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

.header-logo {
  width: 104.9px;
  height: 26.22px;
  /* Remove gap and opacity properties if not needed */
}

.contact-and-demo {
  display: flex;
  align-items: center;
  gap: 20px;
  /* Space between contact text and button */
}

.contact-text {
  /* Style your contact text here */
  font-family: Figtree;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #575664;
  text-align: center;
  cursor: pointer;

}

.book-a-demo-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 10px 10px;
  border-radius: 999px;
  background: #0B0AED;
  color: #FFFFFF;
  /* Text color */
  border: none;
  cursor: pointer;
  /* gap: 8px; */
  font-family: Figtree;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.download-our-brochure-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px 5px 20px;
  border-radius: 999px;
  background: #0a61ed;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.book-a-demo-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Adds a subtle shadow */
  transition: box-shadow 0.1s;
  /* Smooth transition for the shadow effect */

  background-color: #2b32fd;
  /* New darker blue background color */
  transition: background-color 0.1s;
  /* Smooth transition for the color change */
}

.download-our-brochure-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Adds a subtle shadow */
  transition: box-shadow 0.1s;
  /* Smooth transition for the shadow effect */

  background-color: #226fec;
  /* New darker blue background color */
  transition: background-color 0.1s;
  /* Smooth transition for the color change */
}


.arrow-icon {
  width: 24px;
  /* or the size of your icon */
  height: 24px;
  /* or the size of your icon */
  margin-right: 8px;
  /* space between icon and text */
}

/* ###################################### TITLE AND SUB TITLE ##########################################*/
.hero-section {
  width: 100%;
  /* height: 349px; */
  position: relative;
  /* For z-index stacking */
  z-index: 0;
  /* Behind the header */
  margin-bottom: 10vh;

}

/* Container within Hero Section */
/* .frame2-container {
  width: 952.43px;
  height: 181px;
  margin-top: 147.25px; 
  margin-left: 243.78px; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 34px; 
} */
.frame2-container {
  width: 100%;
  /* Adjust the width as needed */
  margin-top: 347.25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* This will center the children vertically */
  margin: auto;
}



/* Frame 3: Title and Subtitle */
.frame3-title {
  font-family: Figtree;
  font-size: 52px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
}

.non-bold {
  font-weight: normal;
}


.frame3-subtitle {
  font-family: Figtree;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-top: 16px;
}


/* .book-a-demo-button2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 999px;
  background: #0B0AED;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
} */


.vector-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.vector {
  position: absolute;
  background-size: cover;
}

.vector-1 {
  width: 410.18px;
  height: 326.18px;
  background-image: url('@/assets/Vector1.png');

}

.vector-2 {
  width: 148.28px;
  height: 148.28px;
  top: 56.97px;
  /* left: 814.93px; */
  right: 280px;
  background-image: url('@/assets/Vector2.png');

}


.vector-3 {
  width: 250px;
  height: 250px;
  top: -95.5px;
  right: 0;
  background-image: url('@/assets/Vector3.png');

}



/* IFRAMES */


/* ################### CENTER OF THE PAGE 3 IMAGES OF BIOREACTORS AND 01010101010 ########################*/

.stacked-images {
  margin-top: 50px;
  display: flex;
  justify-content: center;

}

.image-container {
  position: relative;
  display: flex;
  /* Add this line */
  justify-content: center;
  /* Add this line */
  width: 100%;
  /* Make it occupy the full width */
  height: 100%;
}

.image {
  position: absolute;
}

.image-1 {
  z-index: 1;
  width: 274.67px;
  height: 369.34px;
  top: 88.22px;
  margin-left: -250px;


  /* width: 274.67px;
height: 369.34px;
top: 88.22px;
gap: 0px;
opacity: 0px; */

}

.image-2 {
  z-index: 2;
  width: 321px;
  height: 535px;
  padding: 1px;
  margin-left: 170px;

}

.image-3 {
  z-index: 3;
  width: 147.65px;
  height: 147.65px;
  top: 40px;
  gap: 7.8px;
  margin-left: 480px;
  border-radius: 1113.68px;
  border: 2.23px;

}

/* Style for the new background images section */
.background-images {
  width: 100%;
  /* Ensure it takes up the full width */
  position: relative;
  z-index: -2;
  /* Ensure it's behind everything else */
}

.background-images-container {
  position: relative;
  width: 100%;
  /* Make it occupy the full width */
  height: 100%;
  display: flex;
  /* Ensure children occupy entire width */
}

.background-image {
  /* width: 100%; */
  /* Each image takes up half of the screen width */
  height: 500px;
  /* Adjust the height as needed */
}

.background-image-0101 {

  margin-top: 120px;
  margin-bottom: 1px;
}

.background-image-0101-mobile {
  display: none;
}

.background-image-1 {
  opacity: 0.3;
  margin-top: 120px;
  margin-bottom: 20px;
}

.background-image-2 {
  opacity: 0.3;
  margin-top: 120px;
  margin-bottom: 20px;
}


/* ############################### styles for the icons texts frame ##################################*/
.icon-frame {
  display: flex;
  justify-content: space-between;
  max-width: 1241px;
  margin-left: auto;
  margin-right: auto;
}

.icon-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(25% - 20px);
  padding: 20px 16px 20px 30px;
  border-right: 1px solid #C8C8D8;
  border-left: 1px solid #C8C8D8;
}

/* .icon-section:last-child {
  border-right: none;
} */
.icon-section:not(:first-child) {
  border-left: none;
}

.icon-image {
  width: 34px;
  height: 34px;
  margin-bottom: 10px;
}

.icon-text {
  font-family: Figtree;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
}


/*####################################### DESKTOP IMAGE ABOVE FOOTER#################################*/


.desktop-image {
  background-image: url("@/assets/desktopScrollingVector.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
  background-size: 80%;
  background-position: center;
  /* Add this line */

  position: relative;
  background-color: #0B0AED;
  border-radius: 12px;
  width: 60%;
  /* Adjust as needed */
  height: 300px;
  /* Adjust as needed */
  margin: 50px auto 0;
  /* 50px top margin, auto left and right margins, 0 bottom margin */
}




.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* Adjust color as needed */
  font-family: Figtree;
  font-size: 32px;
  font-weight: 400;
  line-height: 47.36px;
  text-align: center;
  width: 80%;
  /* Adjust as needed */
}

.thin-text {
  font-weight: 300;
  /* Adjust as needed */
}

.mobile-image {
  display: none;
  /* Ensures the mobile image is not displayed by default */
}

/* ############################################# FOOTER ######################################## */
.footer {

  margin-top: 50px;
  background-color: #030217;
  padding: 30px 0;
  /* margin-left: -8px;
  margin-bottom: -8px;
  margin-right: -8px; */
}

.footer-main-frame {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  opacity: 1;
  margin-left: 100px;
  margin-right: 100px;
}

.footer-frame-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-icon {
  width: 107.49px;
  height: 30.19px;
}

.nav-list {
  font-family: Figtree;
  display: flex;
  align-items: center;
}

.nav-item {
  display: flex;
  align-items: center;

}

.nav-item span {
  color: #ffffff;
}

.get-in-touch {
  cursor: pointer;
}


.linkedin-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.separator-line {
  width: 0;
  height: 22px;
  border-left: 1px solid #374151;
  /* Changed from border-top */
  margin-left: 32px;
  margin-right: 32px;
}

.horizontal-line-mobile {
  display: none;
}

.at1-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.horizontal-line {
  width: 100%;
  border: none;
  border-top: 1px solid #374151;
}

.footer-frame-2 {
  font-family: Figtree;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.copyright-text {
  font-family: Figtree;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;

}

.design-text {
  font-family: Figtree;

  display: flex;
  align-items: center;
  color: #ffffff;
}

.heart-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px;
}

/* ################################# POP UP WINDOWS ######################################### */
/* Styling for the pop-up overlay */

.popup-header {
  font-family: 'Figtree';
  /* font-size: 24px; */
  font-weight: 500;
  /* line-height: 36px; */
  text-align: center;
  /* margin-top: 20px; */
  margin-bottom: 30px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 999;
  /* Ensure it's on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}



/* Styling for the pop-up content */
.popup-content {
  background-color: #fff;
  /* White background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* Shadow effect */
  max-width: 400px;
  /* Set max width for the pop-up */
}

/* Style for the input fields */
.popup-content input,
.popup-content textarea {
  width: 100%;
  margin-bottom: 10px;
  /* Add spacing between input fields */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Figtree;

}

/* Style for the submit and close buttons */
.popup-content button {
  padding: 10px 20px;
  margin-top: 10px;
  /* Add spacing between buttons and inputs */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Style for the close button */
.popup-content button.close {
  background-color: #ccc;
  color: #333;
  margin-right: 10px;
  margin-left: 4px;
  /* Add spacing between buttons */
}

/* Style for the submit button */
.popup-content button.submit {
  background-color: #007bff;
  color: #fff;
  margin: 4px;
}
</style>
